import React, { useMemo, useState, useEffect } from 'react'
import { EstateMediaMenu } from './EstateMediaMenu'
import { FileSize } from '@almaris/shared/helpers/FileSize'
import { FileTextIcon, ImageIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { toast } from '@/components/ui/use-toast'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { useTranslation } from 'react-i18next'
import { useUpdateEstateMediaMutation, WebappEstateMediaFragment } from '@gql'
import { FileUploadDialog, OnUploadProps } from '@/file/FileUploadDialog'
import { useFileDelete } from '@/file/hooks/useFileDelete'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'

export type CensoredEstateMediaProps = {
  censoredVersion: WebappEstateMediaFragment
}

const showToasts = true

export const CensoredEstateMedia = ({
  censoredVersion,
}: CensoredEstateMediaProps) => {
  const { t } = useTranslation()
  const censoredFile = censoredVersion.file
  const [confirmationPendingCallback, setConfirmationPendingCallback] =
    useState<() => Promise<void> | void>()
  const [isOpen, setIsOpen] = useState(false)

  const {
    deleteFile,
    error: fileDeleteError,
    success: fileDeleteSuccess,
    loading: fileDeleteLoading,
  } = useFileDelete(censoredFile.id)

  const [
    updateEstateMediaMutation,
    {
      data: updateEstateMediaMutationData,
      loading: updateEstateMediaMutationLoading,
      error: updateEstateMediaMutationError,
    },
  ] = useUpdateEstateMediaMutation()

  const Icon = useMemo(() => {
    switch (censoredFile.mimeType) {
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return ImageIcon
      default:
        return FileTextIcon
    }
  }, [censoredFile.mimeType])

  const handleEditClick = () => {
    setIsOpen(true)
  }
  const handleDeleteClick = () => {
    setConfirmationPendingCallback(() => () => {
      deleteFile()
    })
  }

  const handleFileUpdate = async ({ id }: OnUploadProps) => {
    setIsOpen(false)
    await deleteFile()
    await updateEstateMediaMutation({
      variables: {
        id: censoredVersion.id,
        values: {
          fileId: id!,
        },
      },
    })
  }

  const {
    url,
    error: getPresignedUrlError,
    loading: getPresignedUrlLoading,
  } = useGetPresignedUrl({
    fileId: censoredFile.id,
    skip: !censoredFile.id,
  })

  useEffect(() => {
    if (showToasts && (fileDeleteError || updateEstateMediaMutationError)) {
      toast({
        variant: 'destructive',
        description: t('CensoredEstateMedia.toast.error'),
      })
    }
  }, [fileDeleteError, updateEstateMediaMutationError])

  useEffect(() => {
    if (showToasts && (fileDeleteSuccess || updateEstateMediaMutationData)) {
      toast({
        variant: 'success',
        description: t('CensoredEstateMedia.toast.success'),
      })
    }
  }, [fileDeleteSuccess, updateEstateMediaMutationData])

  const loading =
    updateEstateMediaMutationLoading ||
    fileDeleteLoading ||
    getPresignedUrlLoading
  const error = getPresignedUrlError
  if (loading) return <Loading active />
  if (error) return <TextError error={error} />

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-center">
      <div className="tw-w-full tw-flex tw-flex-row tw-gap-4 tw-items-center">
        <Link
          className="tw-text-zinc-900 tw-font-medium tw-flex-grow tw-flex tw-items-center tw-gap-4"
          to={url || '#'}
          target="_blank"
        >
          <div className="tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-lg">
            <Icon />
          </div>
          <div>
            <div>{t('CensoredEstateMedia.annotation')}</div>
            <div className="tw-text-zinc-500 tw-font-normal">
              <FileSize size={censoredFile.size || 0} /> ·{' '}
              {censoredFile.mimeType}
            </div>
          </div>
        </Link>
        <EstateMediaMenu
          onDelete={handleDeleteClick}
          onEdit={handleEditClick}
        />
      </div>
      <FileUploadDialog
        onOpenChange={setIsOpen}
        description={t('CensoredEstateMedia.fileUpload.description')}
        onUpload={handleFileUpdate}
        canSetCustomName={false}
        loading={loading}
        open={isOpen}
      />
      <ConfirmationDialog
        open={!!confirmationPendingCallback}
        onOpenChange={() => setConfirmationPendingCallback(undefined)}
        onConfirm={confirmationPendingCallback}
      />
    </div>
  )
}

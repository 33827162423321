import useQueryParams from '@/common/hooks/useQueryParams'
import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Title } from '@/common/atoms/Title'
import { AdminSigninForm } from '../components/AdminSigninForm'
import { useToast } from '@/components/ui/use-toast'
import { trpc } from 'src/trpc'
import { AgencyMemberFragment } from '@gql'
import useAgency from '../../agency/hooks/useAgency'
import { Loading } from '@/common/atoms/Loading'

type Params = {
  id?: string
  token: string
}

export default function AdminInvitationPage() {
  const { t } = useTranslation()
  const { toast } = useToast()
  const { agencyId } = useParams<{ agencyId: string }>()
  const { id: agencyMemberId, token } = useQueryParams<Params>()

  const { agency } = useAgency(agencyId)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [currentUser, setCurrentUser] = useState<
    AgencyMemberFragment | undefined
  >(undefined)

  useEffect(() => {
    if (!agencyMemberId) return
    if (!token) return
    setIsLoading(true)
    setTimeout(() => {
      trpc.agencyMember.acceptAgencyMemberInvitation
        .mutate({ agencyMemberId, token })
        .then((data) => {
          setCurrentUser(data)
        })
        .catch((error) => {
          console.error(error, JSON.stringify(error))
          toast({
            variant: 'destructive',
            title: t('common.error'),
            description: error?.response?.data || error?.message || undefined,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 2000) // Quick fix: wait for Nhost Auth to be ready
  }, [agencyMemberId, token])

  if (isSuccess) return <Navigate to="/" />

  return (
    <div className="tw-space-y-5 tw-mb-5">
      <Title>{t('AdminInvitationPage.title')}</Title>
      {(isLoading || !currentUser) && <Loading active />}
      {!isLoading && currentUser && (
        <>
          <h1 className="tw-text-2xl tw-font-bold">
            {t('AdminInvitationPage.heading', {
              agency: agency?.name,
            })}
          </h1>

          <AdminSigninForm
            agencyMember={currentUser}
            onSuccess={() => setIsSuccess(true)}
          />
        </>
      )}
    </div>
  )
}

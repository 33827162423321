import React, {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react'
import {
  AddressProperties,
  ApiAddressQueryTypeEnum,
  useApiAddress,
} from './useApiAddress'
import { Input, InputProps } from '@/components/ui/input'
import { Card } from '@/components/ui/card'
import { Button } from '@/components/ui/button'

export type Address = {
  address?: string
  zipCode?: string
  city?: string
}

export enum AddressComboboxModeEnum {
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
}

export type AddressComboboxProps = Omit<InputProps, 'onBlur'> &
  Pick<HTMLAttributes<HTMLDivElement>, 'onBlur'> & {
    onSelectItem?: (value: Address) => void
    mode?: AddressComboboxModeEnum
  }

const AddressComboboxInner = (
  {
    onChange,
    onBlur,
    value: _value,
    onSelectItem,
    mode = AddressComboboxModeEnum.ADDRESS,
    ...props
  }: AddressComboboxProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [value, setValue] = useState<string>(String(_value ?? ''))
  useEffect(() => setValue(String(_value ?? '')), [_value])

  const { getResults, data } = useApiAddress({
    type:
      mode !== AddressComboboxModeEnum.ADDRESS
        ? ApiAddressQueryTypeEnum.MUNICIPALITY
        : ApiAddressQueryTypeEnum.HOUSE_NUMBER,
  })

  const [isOpen, setIsOpen] = useState(false)

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue)
    getResults(newValue)
    onChange?.(event)
  }

  const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
    const relatedTarget = e.nativeEvent.relatedTarget as HTMLElement

    // Check if the related target is a button inside the dropdown
    if (
      relatedTarget &&
      relatedTarget.tagName === 'BUTTON' &&
      relatedTarget.closest('.dropdown')
    ) {
      e.preventDefault()
    } else {
      setIsOpen(false)
      onBlur?.(e)
    }
  }

  useEffect(() => {
    if (data.length) {
      setIsOpen(true)
    }
  }, [data])

  const handleItemClick = (item: AddressProperties) => {
    onSelectItem?.({
      address: item.name,
      zipCode: item.postcode,
      city: item.city,
    })
    setIsOpen(false)
  }

  const getLabel = (item: AddressProperties) => {
    switch (mode) {
      case AddressComboboxModeEnum.ADDRESS:
        return item.label
      case AddressComboboxModeEnum.ZIP_CODE:
        return `${item.postcode} ${item.city}`
      case AddressComboboxModeEnum.CITY:
        return `${item.postcode} ${item.city}`
    }
  }

  return (
    <div ref={ref} onBlur={handleBlur}>
      <Input
        type="text"
        onChange={handleSearchChange}
        value={value}
        {...props}
      />
      <div
        className={`dropdown tw-relative tw-mt-1 ${isOpen ? 'tw-block' : 'tw-hidden'}`}
      >
        <Card className="tw-absolute tw-z-9000 tw-w-full tw-flex tw-flex-col">
          {data.map((item) => (
            <Button
              key={item.id}
              onClick={() => handleItemClick(item)}
              variant="ghost"
              className="tw-w-full"
            >
              {getLabel(item)}
            </Button>
          ))}
        </Card>
      </div>
    </div>
  )
}

export const AddressCombobox = forwardRef(AddressComboboxInner)

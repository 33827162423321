import React from 'react'
import { useTranslation } from 'react-i18next'
import { MandateInfoForm } from '../components/MandateInfoForm'
import { MandateStatusCard } from '../components/MandateStatusCard'
import { MandateFragment, useGetMandateQuery } from '@gql'
import { useToast } from '@/components/ui/use-toast'
import debounce from 'lodash.debounce'
import { useCreateMandateMutation, useUpdateMandateMutation } from '@gql'
import useCurrentEstate from '@/estate/hooks/useCurrentEstate'
import TextError from '@/common/atoms/TextError'
import { Loading } from '@/common/atoms/Loading'

export const MandatePage = () => {
  const { t } = useTranslation()
  const currentEstate = useCurrentEstate()
  const estateId = currentEstate?.id

  const mandateId = currentEstate?.mandate?.id

  const { data, loading, error } = useGetMandateQuery({
    variables: {
      id: mandateId!,
    },
    skip: !mandateId,
  })

  const mandate = data?.mandate_by_pk

  const { toast } = useToast()
  const [createMandate] = useCreateMandateMutation({
    refetchQueries: ['getMandate'], // TODO get new data from response instead of refreshing
  })
  const [updateMandate] = useUpdateMandateMutation({
    refetchQueries: ['getMandate'], // TODO get new data from response instead of refreshing
  })

  const onCompleted = () => {
    toast({
      description: t('MandateInfoForm.toast.success'),
      variant: 'success',
    })
  }

  const onError = (error: Error) => {
    console.error(error.message)
    toast({
      description: t('MandateInfoForm.toast.error'),
      variant: 'destructive',
    })
  }

  const debouncedUpdateMandate = debounce(
    (values: Partial<MandateFragment>) =>
      updateMandate({
        variables: {
          id: mandateId!,
          values,
        },
        onCompleted,
        onError,
      }),
    500
  )

  const debouncedCreateMandate = debounce(
    (values: Partial<MandateFragment>) =>
      createMandate({
        variables: {
          values: {
            ...values,
            estateId,
          },
        },
        onCompleted,
        onError,
      }),
    500
  )

  const handleSubmit = (
    values: Partial<MandateFragment>,
    mandateId?: MandateFragment['id']
  ) => {
    if (!estateId) return
    if (mandateId) {
      debouncedUpdateMandate(values)
    } else {
      debouncedCreateMandate(values)
    }
  }

  if (loading || !currentEstate) return <Loading center active />
  if (error) return <TextError error={error} />

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('MandatePage.heading')}
      </h1>
      <div className="tw-w-full tw-grid md:tw-grid-cols-[1fr_40%] tw-gap-8 tw-p-4 md:tw-p-6">
        <MandateInfoForm
          mandate={mandate}
          estate={currentEstate}
          readOnly={!!mandate?.registryNumber}
          onSubmit={handleSubmit}
        />
        <MandateStatusCard mandate={mandate} />
      </div>
    </div>
  )
}

import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { WebappEstateFragment } from '@gql'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type EstateEstimateTodoListProps = {
  estate: WebappEstateFragment
  checkedTodoItems: TodoItemType['id'][]
  setCheckedTodoItems: (items: TodoItemType['id'][]) => void
}

export interface TodoItemType {
  id: 'address' | 'type' | 'surface' | 'room' | 'state'
  label: string
}

export const todoItems: TodoItemType[] = [
  {
    id: 'address',
    label: 'Adresse',
  },
  {
    id: 'type',
    label: 'Type de bien',
  },
  {
    id: 'surface',
    label: 'Surface habitable',
  },
  {
    id: 'room',
    label: 'Nombre de pièces',
  },
  {
    id: 'state',
    label: 'Etat général du logement',
  },
] as const

const checkedLabelStyle = 'tw-text-zinc-400 tw-line-through'

export const EstateEstimateTodoList = ({
  estate,
  checkedTodoItems,
  setCheckedTodoItems,
}: EstateEstimateTodoListProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'EstateEstimateTodoList',
  })
  useEffect(() => {
    let items: TodoItemType['id'][] = []

    if (
      estate.address.length > 0 &&
      estate.city.length > 0 &&
      estate.zipCode.length > 0
    )
      items.push('address')
    if (estate.type) items.push('type')
    if (estate.features?.habitableSurface) items.push('surface')
    if (estate.features?.numberOfRooms) items.push('room')
    if (
      estate.servicesAndConditions &&
      estate.servicesAndConditions.overallCondition
    )
      items.push('state')
    setCheckedTodoItems(items)
  }, [estate])

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-bg-zinc-50 tw-p-4 tw-rounded-lg tw-w-full tw-border">
      <p className="tw-text-sm">{t('heading')}</p>
      <div className="tw-w-full tw-space-y-2">
        {todoItems.map((item) => {
          const isChecked = checkedTodoItems?.some((todo) => todo === item.id)

          return (
            <div
              key={item.id}
              className="tw-flex tw-items-center tw-gap-2 tw-justify-start tw-w-full"
            >
              <Checkbox
                key={item.id}
                id={item.label}
                className="data-[state=checked]:tw-bg-brandGreen tw-rounded-2xl data-[state=checked]:tw-border-none tw-cursor-default"
                checked={isChecked}
                aria-readonly
              />
              <Label
                htmlFor={item.label}
                className={isChecked ? checkedLabelStyle : ''}
              >
                {item.label}
              </Label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

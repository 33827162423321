import { Button } from '@/components/ui/button'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { TimePicker } from '@/components/ui/timePicker'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSettingOffersFormValues,
  useEstateSettingOffersSchema,
} from '@/estate/hooks/useEstateSettingOffersSchema'
import {
  WebappEstateFragment,
  useIncrementApplicationsRoundNumberMutation,
  useUpdateEstateMutation,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { RefreshCcwDotIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/trpc'
import { EstateBuyerMemberMultiSelect } from '../EstateBuyerMember/EstateBuyerMemberMultiSelect'
import debounce from 'lodash.debounce'
import { parseDate } from '@utils/dates'
import { format } from 'date-fns'

type EstateSettingOffersFormProps = {
  estate: WebappEstateFragment
  readOnly?: boolean
}

export const EstateSettingOffersForm = ({
  estate,
  readOnly,
}: EstateSettingOffersFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { schema } = useEstateSettingOffersSchema()

  const [showForm, setShowForm] = useState(!!estate.saleData.secondRound)

  const [updateEstate, { loading }] = useUpdateEstateMutation()
  const debouncedUpdateEstate = debounce(updateEstate, 0)
  const [incrementApplicationsRoundNumber] =
    useIncrementApplicationsRoundNumberMutation()

  const isSaleOpen = useMemo(() => {
    return estate.saleData.saleOpen
  }, [estate.saleData])

  const endDate = parseDate(estate.saleData.secondRound?.endDate)
  const endTime = endDate && format(endDate, 'HH:mm')

  const form = useForm<EstateSettingOffersFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      endDate,
      endTime,
      buyerMemberIds: estate.saleData.secondRound?.buyerMemberIds ?? [],
    },
  })

  const onSubmit = async ({
    endDate,
    endTime,
    buyerMemberIds,
  }: EstateSettingOffersFormValues) => {
    const estateId = estate.id

    const [endHour, endMinute] = endTime.split(':').map(Number)
    endDate.setHours(endHour, endMinute, 0, 0)

    const result = await debouncedUpdateEstate({
      variables: {
        id: estateId,
        values: {
          saleData: {
            ...estate.saleData,
            secondRound: {
              endDate,
              buyerMemberIds,
            },
          },
        },
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: t('EstateSettingOffersForm.toast.error'),
          description: error.message,
        })
      },
    })

    if (result?.data?.update_estate_by_pk) {
      await incrementApplicationsRoundNumber({
        variables: {
          buyerIds: buyerMemberIds,
          estateId,
        },
        onCompleted: () => {
          toast({
            variant: 'success',
            description: t('EstateSettingOffersForm.toast.success'),
          })
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            title: t('EstateSettingOffersForm.toast.error'),
            description: error.message,
          })
        },
      })
      try {
        await trpc.application.sendNewRoundMail.mutate({
          buyerIds: buyerMemberIds,
          estateId,
        })
        toast({
          title: t('EstateSettingOffersForm.toast.sendNewRoundMail.success'),
          variant: 'success',
        })
      } catch (error) {
        toast({
          description: error instanceof Error ? error.message : '',
          variant: 'destructive',
        })
      }
    }
  }

  const onInvalid = (errors: FieldErrors<EstateSettingOffersFormValues>) => {
    console.log({ errors })
  }
  const hasDirtyFields = Object.keys(form.formState.dirtyFields).length > 0

  return (
    <>
      <div className="tw-mt-3 tw-bg-zinc-50 tw-text-zinc-500 tw-flex tw-items-start tw-p-5 tw-justify-between tw-gap-3">
        <div>
          <RefreshCcwDotIcon />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div>
            <p className="tw-font-medium">
              {t('EstateSettingOffersForm.infoCard.title')}
            </p>
            <p className="tw-text-xs">
              {t('EstateSettingOffersForm.infoCard.subTitle')}
            </p>
          </div>
          <div>
            <p className="tw-text-sm">
              {t('EstateSettingOffersForm.infoCard.description')}
            </p>
          </div>
        </div>
        <Button
          disabled={!isSaleOpen || readOnly}
          className="tw-self-end"
          size="sm"
          onClick={() => setShowForm((prev) => !prev)}
        >
          {t('EstateSettingOffersForm.infoCard.button')}
        </Button>
      </div>
      {showForm && (
        <Form {...form}>
          <form
            className="tw-space-y-6 tw-pt-6 tw-h-full"
            onSubmit={form.handleSubmit(onSubmit, onInvalid)}
          >
            <div className="tw-flex tw-space-x-4">
              <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem className="tw-flex tw-flex-col tw-w-full">
                    <FormLabel>
                      {t('EstateSettingOffersForm.form.endDate.date')}
                    </FormLabel>
                    <InputDatePicker
                      {...field}
                      placeholder={t(
                        'EstateSettingOffersForm.form.endDate.placeholder'
                      )}
                      fromDate={new Date(estate.saleData?.firstRound.endDate)}
                      disabled={readOnly}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endTime"
                render={({ field }) => (
                  <FormItem className="tw-flex tw-flex-col tw-w-full">
                    <FormLabel>
                      {t('EstateSettingOffersForm.form.endDate.hour')}
                    </FormLabel>
                    {/* TODO make a component with InputDatePicker that works with dates */}
                    <TimePicker
                      {...field}
                      className="tw-w-full"
                      disabled={readOnly}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="buyerMemberIds"
              render={({ field }) => (
                <EstateBuyerMemberMultiSelect
                  estateId={estate.id}
                  defaultValue={field.value ?? []}
                  onValueChange={field.onChange}
                  disabled={readOnly}
                  {...field}
                />
              )}
            />
            {!readOnly && (
              <Button
                variant="secondary"
                type="submit"
                disabled={!hasDirtyFields || loading}
              >
                {t('EstateSettingOffersForm.form.submit')}
              </Button>
            )}
          </form>
        </Form>
      )}
    </>
  )
}

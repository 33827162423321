import { useState } from 'react'
import debounce from 'lodash.debounce'

type Coordinates = [number, number]

// Typage de la géométrie GeoJSON
type Geometry = {
  type: 'Point'
  coordinates: Coordinates
}

// Typage des propriétés d'une adresse dans l'API Adresse de data.gouv.fr
export type AddressProperties = {
  label: string
  score: number
  housenumber?: string
  id: string
  type: string
  name: string
  postcode: string
  citycode: string
  x: number
  y: number
  city: string
  context: string
  importance: number
  street?: string
  distance: number
}

// Typage d'un objet Feature GeoJSON
type Feature = {
  type: 'Feature'
  geometry: Geometry
  properties: AddressProperties
}

// Typage d'un objet FeatureCollection GeoJSON
type FeatureCollection = {
  type: 'FeatureCollection'
  version: string
  features: Feature[]
}

export enum ApiAddressQueryTypeEnum {
  HOUSE_NUMBER = 'housenumber',
  STREET = 'street',
  LOCALITY = 'locality',
  MUNICIPALITY = 'municipality',
}

export type useApiAddressProps = {
  type?: ApiAddressQueryTypeEnum
  limit?: number
  debounceWait?: number
}

export const useApiAddress = ({
  type = ApiAddressQueryTypeEnum.HOUSE_NUMBER,
  limit = 5,
  debounceWait = 250,
}: useApiAddressProps = {}) => {
  const [data, setData] = useState<AddressProperties[]>([])
  const [loading, setLoading] = useState(false)
  const getResults = debounce(async (query: string) => {
    if (query.length < 3) {
      setData([])
      return
    }

    setLoading(true)
    const response = await fetch(
      `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=${limit}&type=${type}&autocomplete=1`
    )
    const data: FeatureCollection = await response.json()
    setData(
      (data.features || [])
        .map((feature) => feature.properties)
        .sort((a, b) => a.score - b.score)
    )
    setLoading(false)
  }, debounceWait)

  return { getResults, data, loading }
}

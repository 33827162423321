import {
  StorageGetPresignedUrlParams,
  StorageGetPresignedUrlResponse,
} from '@nhost/hasura-storage-js'
import { useEffect, useState } from 'react'
import { nhost } from 'src/nhost'

export type UseGetPresignedUrlProps = StorageGetPresignedUrlParams & {
  skip?: boolean
}
export const useGetPresignedUrl = ({
  skip,
  ...props
}: UseGetPresignedUrlProps) => {
  const [url, setUrl] = useState<string>()
  const [expiration, setExpiration] = useState<number>()
  const [error, setError] = useState<StorageGetPresignedUrlResponse['error']>()
  const [params, setParams] = useState<StorageGetPresignedUrlParams>(props)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(props)) {
      setParams(props)
      setUrl(undefined)
      setError(undefined)
      setLoading(true)
      setExpiration(undefined)
    }
  }, [props])

  const refreshUrl = async (params: StorageGetPresignedUrlParams) => {
    if (!params.fileId) return
    if (skip) return
    nhost.storage
      .getPresignedUrl(params)
      .then(({ presignedUrl, error }) => {
        if (error) throw error
        setUrl(presignedUrl.url)
        setExpiration(presignedUrl.expiration)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (expiration) {
      window.setTimeout(() => {
        refreshUrl(params)
      }, expiration * 1000)
    }
  }, [expiration, url])

  useEffect(() => {
    refreshUrl(params)
  }, [params])

  return { url, expiration, error, loading }
}

import { EnumSelect } from '@/enum/EnumSelect'
import { EPC_Rank_Enum } from '@almaris/shared/model/estate'
import React, { forwardRef } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

export type EPCRankSelectProps = {
  label: string
  placeholder: string
  field: ControllerRenderProps<any>
  className?: string
}

const EPCRankSelect = forwardRef<HTMLInputElement, EPCRankSelectProps>(
  (props, ref) => {
    const { label, placeholder } = props

    const entries = Object.values(EPC_Rank_Enum).reduce(
      (acc, type) => {
        acc[type] = type
        return acc
      },
      {} as Record<EPC_Rank_Enum, string>
    )
    return (
      <div>
        <EnumSelect
          {...props}
          entries={entries}
          label={label}
          placeholder={placeholder}
          ref={ref}
        />
      </div>
    )
  }
)

EPCRankSelect.displayName = 'EPCRankSelect'

export default EPCRankSelect

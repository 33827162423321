import React, {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  SellerFragment,
  SellerMemberWithUserAndSellerNamesFragment,
  useGetSellerMembersBySellerIdsQuery,
} from '@gql'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Input } from '@/components/ui/input'
import { Card } from '@/components/ui/card'
import { Button } from '@/components/ui/button'

type Value = SellerMemberWithUserAndSellerNamesFragment['id']
export type SellerMemberComboboxProps = {
  sellerIds: SellerFragment['id'][]
  onChange?: (value: Value) => void
  onBlur?: (e: FocusEvent<HTMLDivElement>) => void
  value?: Value
}

const SellerMemberComboboxInner = (
  { sellerIds, onChange, onBlur, value }: SellerMemberComboboxProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { data, loading, error } = useGetSellerMembersBySellerIdsQuery({
    variables: {
      sellerIds,
    },
  })
  const [search, setSearch] = useState<string>()

  const allMembers = data?.seller_member || []

  const members = allMembers.filter((m) => {
    if (!search) return true
    return (
      m.name?.toLowerCase().includes(search.toLowerCase()) ||
      m.user?.displayName?.toLowerCase().includes(search.toLowerCase()) ||
      m.inviteEmail?.toLowerCase().includes(search.toLowerCase()) ||
      m.user?.email?.toLowerCase().includes(search.toLowerCase())
    )
  })
  const [isOpen, setIsOpen] = useState(false)

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
    const relatedTarget = e.nativeEvent.relatedTarget as HTMLElement

    // Check if the related target is a button inside the dropdown
    if (
      relatedTarget &&
      relatedTarget.tagName === 'BUTTON' &&
      relatedTarget.closest('.dropdown')
    ) {
      e.preventDefault()
    } else {
      setEdit(false)
      setIsOpen(false)
      onBlur?.(e)
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsOpen(true)
  }

  useEffect(() => {
    if (search && members.length) {
      setIsOpen(true)
    }
  }, [members])

  const handleItemClick = (
    item: SellerMemberWithUserAndSellerNamesFragment
  ) => {
    onChange?.(item.id)
    setEdit(false)
    setIsOpen(false)
  }

  const getLabel = (m: SellerMemberWithUserAndSellerNamesFragment) => {
    return `${m.name || m.user?.displayName || m.inviteEmail || m.user?.email} - ${m.seller.name}`
  }

  const defaultValue = useMemo(() => {
    return members.find((m) => m.id === value)
  }, [members, value])

  const [edit, setEdit] = useState(false)

  const handleEditClick = () => {
    setEdit(true)
    setIsOpen(true)
  }

  if (loading) return <Loading center active />
  if (error) return <TextError error={error} />

  if (defaultValue && !edit)
    return (
      <Button
        onClick={handleEditClick}
        variant="secondary"
        className="tw-w-full"
      >
        {getLabel(defaultValue)}
      </Button>
    )
  return (
    <div ref={ref} onBlur={handleBlur} onFocus={handleFocus}>
      <Input type="text" onChange={handleSearchChange} />
      <div
        className={`dropdown tw-relative tw-mt-1 ${isOpen ? 'tw-block' : 'tw-hidden'}`}
      >
        <Card className="tw-absolute tw-z-9000 tw-w-full tw-flex tw-flex-col">
          {members.map((m) => (
            <Button
              key={m.id}
              onClick={() => handleItemClick(m)}
              variant="ghost"
              className="tw-w-full"
            >
              {getLabel(m)}
            </Button>
          ))}
        </Card>
      </div>
    </div>
  )
}

export const SellerMemberCombobox = forwardRef(SellerMemberComboboxInner)

import { Mandate_Fee_Person_Enum, Mandate_Type_Enum } from '@gql'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useMandateInfoSchema = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'Forms.errors' })

  const schema = useMemo(
    () =>
      yup.object().shape({
        type: yup
          .mixed<Mandate_Type_Enum>()
          .oneOf(Object.values(Mandate_Type_Enum)),
        registryNumber: yup.string().optional(),
        nameOfRepresentatives: yup.string().optional(),
        addressOfRepresentatives: yup.string().optional(),
        object: yup.string().optional(),
        estate: yup.string().optional(),
        amendmentNumber: yup.string().optional(),
        precontractualDate: yup.date().optional(),
        mainSignDate: yup.date().optional(),
        reservePrice: yup.string().optional(),
        firstPrice: yup.string().optional(),
        effectiveDate: yup.date().optional(),
        initialDuration: yup
          .number()
          .typeError(t('number'))
          .required(t('required')), // default = 6
        renewalDuration: yup
          .number()
          .typeError(t('number'))
          .required(t('required')), // default = 3
        maxRenewal: yup.number().typeError(t('number')).required(t('required')), // default = 6
        fixedFee: yup.number().typeError(t('number')).required(t('required')), // default = 3500
        transactionalFee: yup
          .number()
          .typeError(t('number'))
          .required(t('required')), // default = 3.5
        earnedMoney: yup.number().typeError(t('number')).optional(), // default = 10
        earnedMoneyPaid: yup.number().typeError(t('number')).optional(), // default = 5
        feePerson: yup
          .mixed<Mandate_Fee_Person_Enum>()
          .oneOf(Object.values(Mandate_Fee_Person_Enum)),
        feeAmount: yup.number().typeError(t('number')).required(t('required')),
        particularConditions: yup.string().optional(),
        cancellationDate: yup.date().optional(),
        effectiveCancellationDate: yup.date().optional(),
      }),
    [t]
  )

  return { schema }
}

export type MandateInfoFormValues = yup.InferType<
  ReturnType<typeof useMandateInfoSchema>['schema']
>

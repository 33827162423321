import { Badge } from '@/components/ui/badge'
import { WebappEstateFragment } from '@gql'
import { isFuture, isPast, isWithinInterval } from 'date-fns'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type EstateStatusBadgeProps = {
  estate: WebappEstateFragment
}

// En cours de complétion ( de la création jusqu’à chargement du fichier mandat)
// Mandat signé ( fichier mandat chargé mais date de début d’AO non atteinte)
// Appel d’offre en cours ( AO en cours)
// Appel d’offre terminé ( AO terminé)
// Archivé ( Archivé)

export const EstateStatusBadge = ({ estate }: EstateStatusBadgeProps) => {
  const { t } = useTranslation()

  const mandateSignedStatus = useMemo(() => {
    const hasMandateMedia =
      estate.mandate?.mandate_media && estate.mandate?.mandate_media.length > 0
    const saleIsOpen =
      estate.saleData.saleOpen &&
      isFuture(new Date(estate.saleData.firstRound.startDate))
    return saleIsOpen && hasMandateMedia
  }, [estate])

  const aoInProgressStatus = useMemo(() => {
    const startDate = new Date(estate.saleData.firstRound.startDate)

    const endDate = estate.saleData.secondRound
      ? new Date(estate.saleData.secondRound.endDate)
      : new Date(estate.saleData.firstRound.endDate)

    return (
      estate.saleData?.saleOpen &&
      startDate &&
      endDate &&
      isWithinInterval(new Date(), {
        start: startDate,
        end: endDate,
      })
    )
  }, [estate])

  const aoDone = useMemo(() => {
    const endDate = estate.saleData.secondRound
      ? new Date(estate.saleData.secondRound.endDate)
      : new Date(estate.saleData.firstRound.endDate)
    return estate.saleData?.saleOpen && isPast(endDate)
  }, [])

  if (estate.archived)
    return (
      <Badge variant="destructive" className="tw-ml-2">
        {t('EstateStatusBadge.archived')}
      </Badge>
    )
  else if (mandateSignedStatus)
    return <Badge>{t('EstateStatusBadge.mandateSigned')}</Badge>
  else if (aoInProgressStatus)
    return <Badge>{t('EstateStatusBadge.aoInProgress')}</Badge>
  else if (aoDone)
    return <Badge variant="success">{t('EstateStatusBadge.aoDone')}</Badge>

  return <Badge variant="secondary">{t('EstateStatusBadge.onGoing')}</Badge>
}

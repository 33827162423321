import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Estate_Media_Type_Group_Enum,
  Estate_Media_Type_Name_Enum,
  WebappEstateFragment,
} from '@gql'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { EstateMedia } from '../EstateMedia/EstateMedia'
import { MissingEstateMedia } from '../EstateMedia/MissingEstateMedia'
import { NewEstateMedia } from '../EstateMedia/NewEstateMedia'

const config = {
  expectedMediaTypes: [
    Estate_Media_Type_Name_Enum.Title,
    Estate_Media_Type_Name_Enum.CadastralPlan,
    Estate_Media_Type_Name_Enum.UrbanismNotice,
    Estate_Media_Type_Name_Enum.InsuranceCertificate,
    Estate_Media_Type_Name_Enum.Plans,
    Estate_Media_Type_Name_Enum.PropertyTax,
    Estate_Media_Type_Name_Enum.ResidenceOrEmptyPropertyTax,
    Estate_Media_Type_Name_Enum.DraftCompromise,
  ],
}

export type EstateEstateTabProps = {
  estate: WebappEstateFragment
}
export const EstateEstateTab = ({ estate }: EstateEstateTabProps) => {
  const { t } = useTranslation()

  const estateMedias = useMemo(() => {
    return estate.estate_media.filter(
      (em) => em.type.group === Estate_Media_Type_Group_Enum.Estate
    )
  }, [estate])

  const expectedEstateMedias = useMemo(() => {
    return config.expectedMediaTypes
      .map((name) => {
        const estateMedia = estate.estate_media.find(
          (em) =>
            em.type.group === Estate_Media_Type_Group_Enum.Estate &&
            em.type.name === name &&
            em.isCensored === false
        )
        return estateMedia
      })
      .filter((e) => !!e)
  }, [estate])

  const otherEstateMedias = useMemo(() => {
    return estateMedias.filter(
      (em) =>
        em.type.group === Estate_Media_Type_Group_Enum.Estate &&
        em.type.name === Estate_Media_Type_Name_Enum.Other
    )
  }, [estateMedias])

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Estate,
  })

  const expectedMediaTypes = useMemo(() => {
    return estateMediaTypes.filter((type) =>
      config.expectedMediaTypes.includes(type.name)
    )
  }, [estateMedias, estateMediaTypes])

  const missingMediaTypes = useMemo(() => {
    return expectedMediaTypes.filter(
      (type) => !estateMedias.find((em) => em.type.id === type.id)
    )
  }, [estateMedias, expectedMediaTypes])

  const otherType = useMemo(() => {
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.Other
    )
  }, [estateMediaTypes])

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateEstateTab.title')}
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-[650px]">
        {expectedEstateMedias.map((estateMedia) => (
          <EstateMedia
            key={estateMedia.id}
            estate={estate}
            estateMedia={estateMedia}
          />
        ))}
        {otherEstateMedias.map((estateMedia) => (
          <EstateMedia
            key={estateMedia.id}
            estate={estate}
            estateMedia={estateMedia}
          />
        ))}
        {missingMediaTypes.map((missingMediaType) => (
          <MissingEstateMedia
            key={missingMediaType.id}
            type={missingMediaType}
            estate={estate}
          />
        ))}
        {otherType && <NewEstateMedia estate={estate} type={otherType} />}
      </div>
    </>
  )
}

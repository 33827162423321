import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSettingLinkFormValues,
  useEstateSettingLinkFormSchema,
} from '@/estate/hooks/useEstateSettingLinkFormSchema'
import {
  WebappEstateFragment,
  useCreateEstateLinkMutation,
  useDeleteEstateLinkMutation,
  useUpdateEstateLinkMutation,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import debounce from 'lodash.debounce'
import { CheckIcon, PlusIcon, XIcon } from 'lucide-react'
import React, { useEffect, useMemo } from 'react'
import { FieldErrors, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type EstateSettingLinkFormProps = {
  estate: WebappEstateFragment
  readOnly?: boolean
}

export const EstateSettingLinkForm = ({
  estate,
  readOnly,
}: EstateSettingLinkFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const links = estate.estate_links ?? []
  const [createLink] = useCreateEstateLinkMutation({
    refetchQueries: ['getEstate', 'EstateDocument'],
  })
  const [updateLink] = useUpdateEstateLinkMutation({
    refetchQueries: ['getEstate', 'EstateDocument'],
  })
  const debounceUpdateLink = debounce(updateLink, 500)
  const [deleteLink] = useDeleteEstateLinkMutation({
    refetchQueries: ['getEstate', 'EstateDocument'],
  })

  const { schema } = useEstateSettingLinkFormSchema()

  const defaultValues = useMemo(() => {
    return {
      links: links.map((link) => ({
        id: link.id,
        name: link.name,
        url: link.url || undefined,
      })),
    }
  }, [links])

  const form = useForm<EstateSettingLinkFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const linksField = useFieldArray({
    control: form.control,
    name: 'links',
  })

  const onSubmit = async (input: EstateSettingLinkFormValues) => {
    //Find links to remove from defaultValues to get db id
    const linkToRemove = defaultValues.links.filter(
      (obj1) => !input.links.some((obj2) => obj2.id === obj1.id)
    )

    input.links.forEach(async (link) => {
      if (linkToRemove.length > 0) {
        linkToRemove.forEach(async (linkToRemove) => {
          await deleteLink({
            variables: {
              id: linkToRemove.id,
            },
            onCompleted: () => {
              toast({
                variant: 'success',
                description: t('EstateSettingModal.toast.success'),
              })
            },
            onError: (error) => {
              toast({
                variant: 'destructive',
                description: error.message,
              })
            },
          })
        })
      }

      if (link.id) {
        await debounceUpdateLink({
          variables: {
            id: link.id,
            values: {
              name: link.name,
              url: link.url,
            },
          },
          onCompleted: () => {
            toast({
              variant: 'success',
              description: t('EstateSettingModal.toast.success'),
            })
          },
          onError: (error) => {
            toast({
              variant: 'destructive',
              description: error.message,
            })
          },
        })
      } else {
        await createLink({
          variables: {
            values: {
              estateId: estate.id,
              name: link.name,
              url: link.url,
            },
          },
          onCompleted: () => {
            toast({
              variant: 'success',
              description: t('EstateSettingModal.toast.success'),
            })
          },
          onError: (error) => {
            toast({
              variant: 'destructive',
              description: error.message,
            })
          },
        })
      }
    })
  }

  //Delete link from form field array
  const handleDeleteLink = (field: any) => async () => {
    const idx = linksField.fields.findIndex((link) => link.id === field.id)
    linksField.remove(idx)
  }

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  const onInvalid = (errors: FieldErrors<EstateSettingLinkFormValues>) => {
    console.log({ errors })
  }

  useEffect(() => {
    const { unsubscribe } = form.watch((value, { type }) => {
      if (type !== 'change') return
      form.handleSubmit(onSubmit, onInvalid)()
    })
    return () => unsubscribe()
  }, [form.watch])

  return (
    <Form {...form}>
      <form>
        <div className="tw-mt-3 tw-space-y-4">
          {linksField.fields.map((field, idx) => {
            return (
              <div
                key={field.id}
                className="tw-flex tw-items-center tw-justify-between tw-bg-zinc-50 tw-rounded-md"
              >
                <div className="tw-flex tw-items-center tw-space-x-2">
                  {field.url && field.url.length > 0 && (
                    <div className="tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 tw-bg-green-100 tw-rounded tw-p-2">
                      <CheckIcon className="tw-text-green-600" />
                    </div>
                  )}
                  {!field.url && (
                    <div className="tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 tw-bg-red-100 tw-rounded tw-p-2">
                      <XIcon className="tw-text-red-600" />
                    </div>
                  )}
                  <FormField
                    control={form.control}
                    name={`links.${idx}.id`}
                    render={({ field }) => (
                      <FormItem className="tw-hidden">
                        <FormControl>
                          <Input {...field} disabled={readOnly} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`links.${idx}.name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            placeholder={t(
                              'EstateSettingLinkForm.namePlaceholder'
                            )}
                            disabled={readOnly}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`links.${idx}.url`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            placeholder={t(
                              'EstateSettingLinkForm.urlPlaceholder'
                            )}
                            className="tw-bg-zinc-100 tw-border-none"
                            readOnly={readOnly}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {!readOnly && (
                    <>
                      <Button
                        variant="outline"
                        type="submit"
                        className="tw-text-sm"
                      >
                        {t('EstateSettingLinkForm.update')}
                      </Button>
                      <Button
                        variant="ghost"
                        type="button"
                        onClick={handleDeleteLink(field)}
                      >
                        <XIcon className="tw-h-4 tw-w-4" />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )
          })}
          {!readOnly && (
            <Button
              variant="outline"
              type="button"
              className="tw-flex tw-items-center tw-space-x-2"
              onClick={() =>
                linksField.append({
                  id: '',
                  name: '',
                  url: '',
                })
              }
            >
              <PlusIcon className="tw-w-4 tw-h-4" />
              <span>{t('EstateSettingLinkForm.more')}</span>
            </Button>
          )}
        </div>
      </form>
    </Form>
  )
}

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { WebappEstateFragment } from '@gql'
import { useDebounce } from '@utils/useDebounce'
import { MapPinIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateCardListEmptyMedia } from './EstateCardListEmptyMedia'
import EmptyEstateSvg from 'src/images/empty-estate.svg'
import { EstateAddFormModal } from '../Estate/EstateAddFormModal'
import { EstateCardListMediaPreview } from './EstateCardListMediaPreview'
import { EstatesCardListFooter } from './EstatesCardListFooter'

type EstatesTableProps = {
  estates?: WebappEstateFragment[]
  setIncludeArchived: (value: boolean) => void
}

export const EstatesCardList = ({
  estates,
  setIncludeArchived,
}: EstatesTableProps) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return estates

    return estates?.filter((estate) => {
      const lowercasedTerm = debouncedSearchTerm.toLowerCase()
      return (
        (estate.name && estate.name.toLowerCase().includes(lowercasedTerm)) ||
        estate.address.toLowerCase().includes(lowercasedTerm) ||
        estate.city.toLowerCase().includes(lowercasedTerm) ||
        estate.zipCode.includes(lowercasedTerm)
      )
    })
  }, [debouncedSearchTerm, estates])

  const renderSaleData = (estate: WebappEstateFragment) => {
    const firstRoundSaleData = estate.saleData.firstRound

    return (
      <div>
        <CardTitle className="tw-text-lg">{estate.name}</CardTitle>
        {firstRoundSaleData?.firstPrice ? (
          <p className="tw-text-base tw-font-medium">
            {firstRoundSaleData?.firstPrice} €
          </p>
        ) : (
          <p className="tw-text-base tw-font-medium">
            {t('EstatesCardList.noPrice')}
          </p>
        )}
      </div>
    )
  }
  return (
    <div className="tw-grid tw-space-y-4 tw-p-8">
      <div className="tw-flex tw-flex-1 tw-items-center tw-space-x-2">
        <Input
          placeholder={t('EstatesTableToolbar.searchPlaceholder')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="tw-max-w-sm"
        />
        <div className="tw-flex tw-items-center tw-space-x-2">
          <Checkbox
            id="archived"
            onCheckedChange={(event) => setIncludeArchived(event as boolean)}
          />
          <Label
            htmlFor="archived"
            className="tw-text-sm tw-font-medium peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70"
          >
            {t('EstatesTableToolbar.checkbox.archived')}
          </Label>
        </div>
      </div>
      {!filteredData?.length && <EmptyEstateState />}

      <div className="tw-flex tw-flex-1 tw-space-x-3 tw-space-y-3 tw-flex-wrap">
        {filteredData?.map((estate, index) => (
          <Card key={index} className="tw-group">
            <CardHeader className="tw-justify-center tw-relative">
              {estate.estate_media.length > 0 ? (
                <EstateCardListMediaPreview estate={estate} />
              ) : (
                <EstateCardListEmptyMedia estate={estate} />
              )}
            </CardHeader>

            <CardContent className="tw-grid tw-gap-4 tw-text-lg">
              {renderSaleData(estate)}
              <div>
                <CardDescription className="tw-text-sm tw-text-zinc-900">
                  {t('EstatesCardList.description', {
                    count: 3,
                  })}
                </CardDescription>
                <div className="tw-flex tw-items-center tw-space-y-0">
                  <MapPinIcon size={13} />
                  <p className="tw-text-xs tw-font-semibold tw-ml-1">
                    {estate.city}
                  </p>
                </div>
              </div>
            </CardContent>
            <EstatesCardListFooter estate={estate} />
          </Card>
        ))}
      </div>
    </div>
  )
}

export const EmptyEstateState = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-gap-6">
      <EmptyEstateSvg />
      <p className="tw-text-zinc-900  tw-text-sm tw-max-w-80 tw-text-center">
        {t('EstatesCardList.emptyState.title', {
          agency: 'Almaris',
        })}
      </p>
      <EstateAddFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}

import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Estate_Media_Type_Group_Enum,
  Estate_Media_Type_Name_Enum,
  WebappEstateFragment,
} from '@gql'
import { EstateMediaFileUploadDialog } from '../EstateMedia/EstateMediaFileUploadDialog'
import { Button } from '@/components/ui/button'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { EstateMedia } from '../EstateMedia/EstateMedia'

export type EstateOtherMediaListProps = {
  estate: WebappEstateFragment
}
export const EstateOtherMediaList = ({ estate }: EstateOtherMediaListProps) => {
  const { t } = useTranslation()

  const estateMedias = useMemo(() => {
    return estate.estate_media.filter(
      (em) => em.type.group === Estate_Media_Type_Group_Enum.Other
    )
  }, [estate])

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Other,
  })
  const estateMediaTypeDiagnosticOther = useMemo(() => {
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.Other
    )
  }, [estateMediaTypes])

  const [open, setOpen] = useState(false)

  const handleFileUpload = () => {
    setOpen(false)
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {estateMedias.map((estateMedia) => (
        <EstateMedia
          key={estateMedia.id}
          estate={estate}
          estateMedia={estateMedia}
          canSetCustomName={true}
        />
      ))}
      <div className="tw-bg-gray-100 tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-between tw-rounded-lg tw-p-4">
        <div>{t('EstateMedias.addFile')}</div>
        <Button variant="outline" onClick={() => setOpen(true)}>
          {t('common.add')}
        </Button>
      </div>
      <EstateMediaFileUploadDialog
        estate={estate}
        showToasts={true}
        open={open}
        onOpenChange={setOpen}
        type={estateMediaTypeDiagnosticOther!}
        onUpload={handleFileUpload}
        canSetCustomName={true}
      />
    </div>
  )
}

import ApplicationEntry from '@/application/components/sheet/ApplicationEntry'
import { Badge } from '@/components/ui/badge'
import { Application_Status_Enum, ApplicationsSubscription } from '@gql'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationMediasPreview } from './ApplicationMediasPreview'

type SellerContentProps = {
  application: ApplicationsSubscription['application'][number]
}

const SellerContent = ({ application }: SellerContentProps) => {
  const { t } = useTranslation()

  const isSuccess = useMemo(
    () => application.status === Application_Status_Enum.Submitted,
    [application]
  )

  //TODO: in the future only show the first document and add a button to see the others ?
  const documents = useMemo(() => {
    if (!application.application_medias) return t('ApplicationSheet.notDefined')

    return (
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {application.application_medias?.map((applicationMedia) => (
          <ApplicationMediasPreview
            key={applicationMedia.id}
            applicationMedia={applicationMedia}
          />
        ))}
      </div>
    )
  }, [application])

  const contributionPercent = useMemo(() => {
    if (!application) return 0
    const { offerAmount, contribution } = application
    if (!offerAmount || !contribution) return 0
    return (contribution / offerAmount) * 100
  }, [application])

  if (!application) return null

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <ApplicationEntry
        title={t('ApplicationsTableColumns.status')}
        value={
          <Badge
            variant={isSuccess ? 'success' : 'secondary'}
            className="tw-ml-2"
          >
            {t(`common.applicationStatus.${application.status}`)}
          </Badge>
        }
      />
      <ApplicationEntry
        title={t('ApplicationSheet.totAmount')}
        value={(application.offerAmount ?? 0).toFixed(2) + '€'}
      />

      <ApplicationEntry
        title={t('ApplicationsTableColumns.visitDate')}
        value={
          // TODO MAKE IT WORK
          application.buyer_member.visit_slots?.[0]?.startTime
            ? format(
                new Date(application.buyer_member.visit_slots[0].startTime),
                'dd MMMM yyyy HH:mm',
                { locale: fr }
              )
            : t('ApplicationSheet.notDefined')
        }
      />
      <ApplicationEntry
        title={t('ApplicationSheet.offerDate')}
        value={
          application.updatedAt
            ? format(new Date(application.updatedAt), 'dd MMMM yyyy HH:mm', {
                locale: fr,
              })
            : t('ApplicationSheet.notDefined')
        }
      />
      <ApplicationEntry
        title={t('ApplicationSheet.contribution')}
        value={contributionPercent.toFixed(2) + '%'}
      />
      <ApplicationEntry
        title={t('ApplicationSheet.loanAmount')}
        value={(application.loan ?? 0).toFixed(2) + '€'}
      />
      <ApplicationEntry
        title={t('ApplicationSheet.documents')}
        value={documents}
      />
      <ApplicationEntry
        title={t('ApplicationSheet.comment')}
        value={application.comment ?? t('ApplicationSheet.notDefined')}
      />
    </div>
  )
}

export default SellerContent

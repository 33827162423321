import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { WebappEstateFragment, useUpdateEstateMutation } from '@gql'
import { toast } from '@/components/ui/use-toast'
import debounce from 'lodash.debounce'
import { EstateDiagnosticsMediaList } from './EstateDiagnosticsMediaList'
import { EstateDiagnosticsDataForm } from './EstateDiagnosticsDataForm'

export type EstateDiagnosticsTabProps = {
  estate: WebappEstateFragment
}
export const EstateDiagnosticsTab = ({ estate }: EstateDiagnosticsTabProps) => {
  const { t } = useTranslation()
  const [
    updateEstate,
    {
      data: updateEstateData,
      // loading: updateEstateLoading,
      error: updateEstateError,
    },
  ] = useUpdateEstateMutation()

  useEffect(() => {
    if (updateEstateData) {
      toast({
        variant: 'success',
        description: t('EstateDiagnosticsTab.toast.success'),
      })
    }
  }, [updateEstateData])

  useEffect(() => {
    if (updateEstateError) {
      toast({
        variant: 'destructive',
        description: t('EstateDiagnosticsTab.toast.error'),
      })
    }
  }, [updateEstateError])

  const debouncedUpdateEstate = useMemo(
    () =>
      debounce((values: Partial<WebappEstateFragment>) => {
        updateEstate({
          variables: {
            id: estate.id,
            values,
          },
        })
      }, 500),
    [estate.id, updateEstate]
  )

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateDiagnosticsTab.title')}
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-[650px]">
        <EstateDiagnosticsMediaList estate={estate} />
        <EstateDiagnosticsDataForm
          estate={estate}
          onSubmit={debouncedUpdateEstate}
        />
      </div>
    </>
  )
}

import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { useNameSchema } from '@/common/hooks/schemas/useNameSchema'
import { isPhoneNumberOrEmpty } from '@almaris/shared/schemas'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useMemberInfoSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const { schema: nameSchema } = useNameSchema()
  const { schema: emailSchema } = useEmailSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: nameSchema.optional(),
        email: emailSchema.optional(),
        phone: yup
          .string()
          .test('phone', t('phone'), isPhoneNumberOrEmpty)
          .optional(),
        job: yup.string().optional(),
      }),
    [t]
  )

  return { schema }
}

export type MemberInfoFormValues = yup.InferType<
  ReturnType<typeof useMemberInfoSchema>['schema']
>

import { format, Locale } from 'date-fns'
import { fr } from 'date-fns/locale'

// From https://developer.mozilla.seller/en-US/docs/Web/HTML/Element/input/datetime-local
export function getDateTimeLocal(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm")
}

export function getTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function listTimeZones(): string[] {
  return Intl.supportedValuesOf('timeZone')
}

export const isValidDate = (value?: string | Date): value is string | Date => {
  if (!value) return false
  return !isNaN(new Date(value).getTime())
}

export const parseDate = (date?: any) => {
  if (isValidDate(date)) return new Date(date)
  return
}

export type FormatDateOptions = {
  locale?: Locale
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  firstWeekContainsDate?: number
  useAdditionalWeekYearTokens?: boolean
  useAdditionalDayOfYearTokens?: boolean
  format?: string
}
export const formatDate = (
  date: Date | string | undefined,
  options: FormatDateOptions
) => {
  const { format: _format, ...optionsWithDefault } = {
    locale: fr,
    format: 'P',
    ...options,
  }
  const parsedDate = parseDate(date)
  return parsedDate && format(parsedDate, _format, optionsWithDefault)
}

import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { InputPrice } from '@/components/ui/inputPrice'
import { useToast } from '@/components/ui/use-toast'
import {
  EstimateEstateFormValues,
  useEstimateEstateSchema,
} from '@/estate/hooks/useEstimateEstateSchema'
import { WebappEstateFragment, useUpdateEstateMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  EstateEstimateTodoList,
  todoItems,
  TodoItemType,
} from './EstateEstimateTodoList'
import { EstateSendMailEstimateButton } from './EstateSendMailEstimateButton'

type EstateEstimatePriceCardProps = {
  estate: WebappEstateFragment
}
export const EstateEstimatePriceCard = ({
  estate,
}: EstateEstimatePriceCardProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { schema } = useEstimateEstateSchema()

  const [checkedTodoItems, setCheckedTodoItems] = useState<
    TodoItemType['id'][]
  >([])

  const isAgencyMember = useIsAgencyMember()

  const canAskEstimate =
    checkedTodoItems.length === todoItems.length &&
    !isAgencyMember &&
    !estate.startEstimate &&
    !estate.endEstimate

  const [updateEstate] = useUpdateEstateMutation()

  const form = useForm<EstimateEstateFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      startEstimate: estate.startEstimate ?? undefined,
      endEstimate: estate.endEstimate ?? undefined,
    },
  })

  const onSubmit = async (values: EstimateEstateFormValues) => {
    if (!estate.id) return
    await updateEstate({
      variables: {
        id: estate.id,
        values: {
          startEstimate: values.startEstimate,
          endEstimate: values.endEstimate,
        },
      },
      onCompleted: () => {
        toast({
          description: t('EstateEstimatePriceCard.toast.success'),
          variant: 'success',
        })
      },
      onError: (error) => {
        toast({
          description: error.message,
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <div className="tw-space-y-4">
      <p className="tw-text-2xl tw-font-semibold">
        {t('EstateEstimatePriceCard.heading')}
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card>
            <CardContent className="tw-grid tw-gap-4 tw-pt-4">
              <EstateEstimateTodoList
                estate={estate}
                checkedTodoItems={checkedTodoItems}
                setCheckedTodoItems={setCheckedTodoItems}
              />
              <div className="tw-flex tw-space-x-2 tw-items-center tw-w-full">
                <p className="tw-text-sm">
                  {t('EstateEstimatePriceCard.between')}
                </p>
                <FormField
                  control={form.control}
                  name="startEstimate"
                  render={({ field }) => (
                    <FormItem className="tw-w-full">
                      <FormControl>
                        <InputPrice
                          step={1}
                          suffix="€"
                          value={field.value}
                          onValueChange={(value) => field.onChange(value)}
                          readOnly={!isAgencyMember}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <p className="tw-text-sm">{t('EstateEstimatePriceCard.and')}</p>
                <FormField
                  control={form.control}
                  name="endEstimate"
                  render={({ field }) => (
                    <FormItem className="tw-w-full">
                      <FormControl>
                        <InputPrice
                          step={1}
                          suffix="€"
                          value={field.value}
                          onValueChange={(value) => field.onChange(value)}
                          readOnly={!isAgencyMember}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {isAgencyMember && (
                <Button
                  type="submit"
                  className="tw-justify-self-start"
                  disabled={estate.archived}
                >
                  {t('EstateEstimatePriceCard.button.estimate')}
                </Button>
              )}
              {canAskEstimate && (
                <EstateSendMailEstimateButton estate={estate} />
              )}
            </CardContent>
          </Card>
        </form>
      </Form>
    </div>
  )
}

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { WebappEstateFragment, useUpdateEstateMutation } from '@gql'
import { EstateVirtualVisit } from '@almaris/shared/model/estate'
import { Input } from '@/components/ui/input'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { Check, X } from 'lucide-react'
import debounce from 'lodash.debounce'
const isValidUrl = (url: string) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export type EstateMediasVirtualVisitTabProps = {
  estate: WebappEstateFragment
}

export const EstateMediasVirtualVisitTab = ({
  estate,
}: EstateMediasVirtualVisitTabProps) => {
  const { t } = useTranslation()
  const [virtualVisitsList, setVirtualVisitsList] = useState<
    EstateVirtualVisit[]
  >(estate.virtualVisits?.list ?? [])
  const [updateEstateMutation, { data, error }] = useUpdateEstateMutation()

  const update = useMemo(
    () =>
      debounce((list: EstateVirtualVisit[]) => {
        updateEstateMutation({
          variables: {
            id: estate.id,
            values: {
              virtualVisits: { list: list.filter((v) => isValidUrl(v.url)) },
            },
          },
        })
      }, 500),
    [estate.id, updateEstateMutation]
  )

  useEffect(() => {
    if (error) {
      toast({
        variant: 'destructive',
        description: t('EstateMediasVirtualVisitTab.toast.error'),
      })
    }
  }, [error])

  useEffect(() => {
    if (data) {
      toast({
        variant: 'success',
        description: t('EstateMediasVirtualVisitTab.toast.success'),
      })
    }
  }, [data])

  const getInputChangeHandler =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      setVirtualVisitsList((prev) => {
        const newVirtualVisits = [...prev]
        newVirtualVisits[index] = { url: event.target.value }
        update(newVirtualVisits)
        return newVirtualVisits
      })
    }
  const handleAddClick = () => {
    setVirtualVisitsList((prev) => {
      const newVirtualVisits = [...prev, { url: '' }]
      update(newVirtualVisits)
      return newVirtualVisits
    })
  }
  const handleDeleteClick = (index: number) => () => {
    setVirtualVisitsList((prev) => {
      const newVirtualVisits = [...prev]
      newVirtualVisits.splice(index, 1)
      update(newVirtualVisits)
      return newVirtualVisits
    })
  }
  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
        {virtualVisitsList.map((virtualVisit, index) => (
          <div
            className="tw-flex tw-items-end tw-gap-4 tw-flex-row"
            key={index}
          >
            <label className="tw-flex tw-flex-col tw-flex-1">
              {t('EstateMediasVirtualVisitTab.url')} *
              <Input
                type="url"
                value={virtualVisit.url}
                onChange={getInputChangeHandler(index)}
              />
            </label>

            {isValidUrl(virtualVisit.url) ? (
              <Check color="green" />
            ) : (
              <X color="red" />
            )}
            <Button onClick={handleDeleteClick(index)}>
              {t('EstateMediasVirtualVisitTab.delete')}
            </Button>
          </div>
        ))}
      </div>
      <Button onClick={handleAddClick}>
        {t('EstateMediasVirtualVisitTab.add')}
      </Button>
    </>
  )
}

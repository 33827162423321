import React, { useMemo, useState } from 'react'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import {
  EstateMediaTypeFragment,
  Estate_Media_Type_Group_Enum,
  Estate_Media_Type_Name_Enum,
  WebappEstateFragment,
} from '@gql'
import { EstateMediaFileUploadDialog } from '../EstateMedia/EstateMediaFileUploadDialog'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { EstateMedia } from '../EstateMedia/EstateMedia'
import { MissingEstateMedia } from '../EstateMedia/MissingEstateMedia'

const expectedMediaTypeConfig = [
  { name: Estate_Media_Type_Name_Enum.CoownershipRegulation, isMultiple: true },
  { name: Estate_Media_Type_Name_Enum.LotsPlan },
  { name: Estate_Media_Type_Name_Enum.DatedPreState },
  { name: Estate_Media_Type_Name_Enum.DatedState },
  { name: Estate_Media_Type_Name_Enum.CoownershipEpc },
  { name: Estate_Media_Type_Name_Enum.GeneralMeetingMinutes, isMultiple: true },
  { name: Estate_Media_Type_Name_Enum.FundCalls },
  { name: Estate_Media_Type_Name_Enum.BuildingMaintenanceLog },
  { name: Estate_Media_Type_Name_Enum.CommonAreaDiagnostics },
  { name: Estate_Media_Type_Name_Enum.CoownershipSummarySheet },
]

export type EstateCoownershipTabProps = {
  estate: WebappEstateFragment
}
export const EstateCoownershipTab = ({ estate }: EstateCoownershipTabProps) => {
  const { t } = useTranslation()

  const estateMedias = useMemo(() => {
    return estate.estate_media.filter(
      (em) => em.type.group === Estate_Media_Type_Group_Enum.Coownership
    )
  }, [estate])

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Coownership,
  })

  const expectedMediaTypes = useMemo(() => {
    return estateMediaTypes.filter((type) =>
      expectedMediaTypeConfig.find((config) => config.name === type.name)
    )
  }, [estateMedias, estateMediaTypes])

  const missingMediaTypes = useMemo(() => {
    return expectedMediaTypes.filter(
      (type) =>
        !estateMedias.find((em) => em.type.id === type.id) ||
        expectedMediaTypeConfig.find(
          (config) => config.name === type.name && config.isMultiple
        )
    )
  }, [estateMedias, expectedMediaTypes])

  const [newEstateMediaType, setNewEstateMediaType] =
    useState<EstateMediaTypeFragment>()

  const handleFileUpload = () => {
    setNewEstateMediaType(undefined)
  }

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateCoownershipTab.title')}
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-[650px]">
        {estateMedias.map((estateMedia) => (
          <EstateMedia
            key={estateMedia.id}
            estate={estate}
            estateMedia={estateMedia}
          />
        ))}
        {missingMediaTypes.map((missingMediaType) => (
          <MissingEstateMedia
            key={missingMediaType.id}
            type={missingMediaType}
            estate={estate}
          />
        ))}
        <div className="tw-bg-gray-100 tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-between tw-rounded-lg tw-p-4">
          <div>{t('EstateMedias.addFile')}</div>
          <Button
            variant="outline"
            onClick={() =>
              setNewEstateMediaType(
                estateMediaTypes.find(
                  (type) => type.name === Estate_Media_Type_Name_Enum.Other
                )
              )
            }
          >
            {t('common.add')}
          </Button>
        </div>
        <EstateMediaFileUploadDialog
          estate={estate}
          showToasts={true}
          open={!!newEstateMediaType}
          onOpenChange={() => setNewEstateMediaType(undefined)}
          type={newEstateMediaType!}
          onUpload={handleFileUpload}
          canSetCustomName={
            newEstateMediaType?.name === Estate_Media_Type_Name_Enum.Other
          }
        />
      </div>
    </>
  )
}

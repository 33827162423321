import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useNameSchema } from '../../common/hooks/schemas/useNameSchema'
import { useEmailSchema } from '../../common/hooks/schemas/useEmailSchema'
import { useSlugSchema } from '../../common/hooks/schemas/useSlugSchema'
import { isPhoneNumber } from '@almaris/shared/schemas'

export const useSellerCreateSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const { schema: nameSchema } = useNameSchema()
  const { schema: emailSchema } = useEmailSchema()
  const { schema: slugSchema } = useSlugSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        seller: yup.object().shape({
          name: nameSchema.required(t('required')),
          slug: slugSchema.required(t('required')),
        }),
        member: yup.object().shape({
          name: nameSchema.required(t('required')),
          email: emailSchema.required(t('required')),
          phone: yup
            .string()
            .test('phone', t('phone'), isPhoneNumber)
            .required(t('required')),
        }),
      }),
    [t]
  )

  return {
    schema,
  }
}

export type SellerCreateFormValues = yup.InferType<
  ReturnType<typeof useSellerCreateSchema>['schema']
>

import React, { ReactNode, useEffect } from 'react'
import { EstateMediaTypeNameTranslation } from './EstateMediaTypeNameTranslation'
import {
  WebappEstateFragment,
  EstateMediaTypeFragment,
  useCreateEstateMediaMutation,
} from '@gql'
import {
  FileUploadDialog,
  FileUploadDialogProps,
  OnUploadProps,
} from '@/file/FileUploadDialog'
import { useTranslation } from 'react-i18next'
import { toast } from '@/components/ui/use-toast'

export type EstateMediaFileUploadDialogProps = Omit<
  FileUploadDialogProps,
  'onUpload'
> & {
  children?: ReactNode
  onUpload?: () => void
  type: EstateMediaTypeFragment
  estate: WebappEstateFragment
  showToasts?: boolean
}
export const EstateMediaFileUploadDialog = ({
  children,
  onUpload,
  type,
  canSetCustomName,
  estate,
  showToasts,
  ...props
}: EstateMediaFileUploadDialogProps) => {
  const { t } = useTranslation()

  const [createEstateMediaMutation, { data, loading, error }] =
    useCreateEstateMediaMutation()

  const handleFileUpload = async ({ id, customName }: OnUploadProps) => {
    if (!id) {
      toast({
        variant: 'destructive',
        description: t('EstateMediaFileUploadDialog.toast.error'),
      })
      return
    }
    await createEstateMediaMutation({
      variables: {
        values: {
          fileId: id,
          typeId: type.id,
          name: canSetCustomName ? customName : undefined,
          estateId: estate.id,
        },
      },
    })
    onUpload?.()
  }

  useEffect(() => {
    if (showToasts && error) {
      toast({
        variant: 'destructive',
        description: t('EstateMediaFileUploadDialog.toast.error'),
      })
    }
  }, [error])

  useEffect(() => {
    if (showToasts && data) {
      toast({
        variant: 'success',
        description: t('EstateMediaFileUploadDialog.toast.success'),
      })
    }
  }, [data])

  return (
    <FileUploadDialog
      description={<EstateMediaTypeNameTranslation type={type} />}
      onUpload={handleFileUpload}
      canSetCustomName={canSetCustomName}
      loading={loading}
      {...props}
    >
      {children}
    </FileUploadDialog>
  )
}

import EmptySaleSvg from 'src/images/empty-sale.svg'
import React, { useMemo, useState } from 'react'
import EstateSettingModal from '../components/EstateSetting/EstateSettingModal'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEstateSubscription } from '@gql'
import { ApplicationsTable } from '@/application/components/table/ApplicationsTable'
import { Separator } from '@/components/ui/separator'
import { ApplicationsStatsBlock } from '@/application/components/ApplicationsStatsBlock'
import { ApplicationsTimeline } from '@/application/components/ApplicationsTimeline'
import { EstateVisitSpanModal } from '../components/EstateVisitSpan/EstateVisitSpanModal'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { isValid } from 'date-fns'
import { Button } from '@/components/ui/button'
import settings from 'src/settings'
import { RequestHandler } from '@utils/RequestHandler'

export const EstateSalePage = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSpan, setIsOpenSpan] = useState(false)
  const isAgencyMember = useIsAgencyMember()

  const { estateId } = useParams<{ estateId: string }>()
  const { data, loading, error } = useEstateSubscription({
    variables: {
      id: estateId!,
    },
    skip: !estateId,
  })
  const estate = useMemo(() => data?.estate_by_pk, [data])

  const openPreviewPage = () => {
    window.open(`${settings.websiteUrl}/estate/${estateId}`, '_blank')
  }

  const isSaleOpen = useMemo(() => {
    if (!estate?.saleData?.firstRound?.endDate) return false
    return (
      estate?.saleData?.firstRound?.endDate.length &&
      estate?.saleData?.firstRound?.endDate.length > 0 &&
      isValid(new Date(estate?.saleData?.firstRound?.endDate))
    )
  }, [data])

  return (
    <RequestHandler
      loading={loading}
      error={error}
      data={data}
      control={(data: any) => data?.estate_by_pk}
      controlError={new Error(t('EstateSalePage.Error.EstateNotFound'))}
    >
      {estate && (
        <div className="tw-h-[calc(100dvh-80px)]">
          {!isSaleOpen && (
            <div className="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6">
              <EmptySaleSvg />
              <p className="tw-text-zinc-900 tw-font-semibold tw-text-lg">
                {isAgencyMember
                  ? t('EstateSalePage.emptyState.title')
                  : t('EstateSalePage.emptyState.sellerTitle', {
                      agency: estate.seller.agency.name,
                    })}
              </p>
              <p className="tw-text-zinc-900 tw-text-sm tw-max-w-80 tw-text-center">
                {t('EstateSalePage.emptyState.description')}
              </p>
              <EstateSettingModal
                estate={estate}
                open={isOpen}
                setOpenModal={setIsOpen}
                disabled={estate.archived}
                readOnly={!isAgencyMember}
              />
            </div>
          )}
          {isSaleOpen && (
            <>
              <ApplicationsStatsBlock estate={estate} />
              <ApplicationsTimeline estate={estate} />
              <Separator />
              <div className="tw-p-8">
                <div className="tw-flex tw-justify-between tw-items-baseline">
                  <h1 className="tw-text-[40px] tw-font-semibold">
                    {t('EstateSalePage.heading')}
                  </h1>
                  <div className="tw-flex tw-gap-2">
                    <Button
                      variant="secondary"
                      onClick={openPreviewPage}
                      disabled={estate.archived}
                    >
                      {t('EstateSalePage.previewButton')}
                    </Button>
                    <EstateVisitSpanModal
                      estate={estate}
                      open={isOpenSpan}
                      setOpenModal={setIsOpenSpan}
                    />
                    <EstateSettingModal
                      estate={estate}
                      open={isOpen}
                      setOpenModal={setIsOpen}
                      disabled={estate.archived}
                      readOnly={!isAgencyMember}
                    />
                  </div>
                </div>
                <ApplicationsTable estate={estate} />
              </div>
            </>
          )}
        </div>
      )}
    </RequestHandler>
  )
}

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { SellerMemberFragment } from '@almaris/backend/src/gql'
import {
  useChangeDisplayNameMutation,
  useUpdateSellerMemberMutation,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nhost } from 'src/nhost'
import {
  MemberInfoFormValues,
  useMemberInfoSchema,
} from '../hooks/useMemberInfoSchema'

type MemberInfoFormProps = {
  member?: SellerMemberFragment
}

const MemberInfoForm = ({ member }: MemberInfoFormProps) => {
  const { t } = useTranslation()

  const { schema } = useMemberInfoSchema()

  const { toast } = useToast()

  const [changeDisplayName] = useChangeDisplayNameMutation()
  const [updateMember] = useUpdateSellerMemberMutation()

  const defaultValues = {
    name: member?.name ?? '',
    email: member?.inviteEmail ?? '',
    phone: member?.phone ?? '',
    job: member?.job ?? '',
  }

  const form = useForm<MemberInfoFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    form.reset(defaultValues)
  }, [member])

  const onSubmit = async (input: MemberInfoFormValues) => {
    if (!member) return

    // Update display name
    if (input.name !== member?.name) {
      await changeDisplayName({
        variables: { userId: member.id, displayName: input.name || '' },
      })
    }

    // Refresh user data
    await nhost.auth.refreshSession()

    await updateMember({
      variables: {
        id: member.id,
        values: { name: input.name, phone: input.phone, job: input.job },
      },
    })

    toast({
      variant: 'success',
      description: t('MemberInfoForm.toast.success'),
    })
  }

  // TODO: FOR UPDATE on blur (and on enter ??, in utils ??)
  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    // Assuming the name of the input matches the field name in the form
    const fieldName = event.target.name as keyof MemberInfoFormValues

    const currentValue = event.target.value
    const initialValue = defaultValues[fieldName]

    // Only submit if the current value is different from the initial value
    if (currentValue !== initialValue) {
      form.handleSubmit(onSubmit)()
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="tw-space-y-5">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.name')}</FormLabel>
                <FormControl>
                  <Input {...field} onBlur={onBlurHandler} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.email')}</FormLabel>
                <FormControl>
                  <Input readOnly disabled {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.phone')}</FormLabel>
                <FormControl>
                  <Input {...field} onBlur={onBlurHandler} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="job"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.job')}</FormLabel>
                <FormControl>
                  <Input {...field} onBlur={onBlurHandler} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  )
}

export { MemberInfoForm }

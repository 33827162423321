import { Title } from '@/common/atoms/Title'
import useQueryParams from '@/common/hooks/useQueryParams'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useResetPassword } from '@nhost/react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ForgotPasswordFormValues,
  useForgotPasswordSchema,
} from '../hooks/useForgotPasswordSchema'

type Params = {
  email: string
}

const ForgotPasswordForm = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const queryParams = useQueryParams<Params>()
  const { resetPassword, isLoading, isSent } = useResetPassword()

  const { schema } = useForgotPasswordSchema()

  const form = useForm<ForgotPasswordFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { email: queryParams.email || '' },
  })

  useEffect(() => {
    if (isSent) {
      toast({
        variant: 'default',
        description: t('ForgotPasswordForm.toast.success'),
      })
    }
  }, [isSent])

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(({ email }: ForgotPasswordFormValues) =>
          resetPassword(email, { redirectTo: '/reset-password' })
        )}
      >
        <Title>{t('ForgotPasswordForm.heading')}</Title>

        <div className="tw-space-y-2 tw-mb-5">
          <h1 className="tw-text-2xl tw-font-bold">
            {t('ForgotPasswordForm.heading')}
          </h1>
          <p className="tw-text-sm">{t('ForgotPasswordForm.description')}</p>
        </div>

        <div className="tw-space-y-5">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('ForgotPasswordForm.email')}</FormLabel>
                <FormControl>
                  <Input defaultValue={queryParams.email} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button variant="green" type="submit" disabled={isLoading}>
            {t('ForgotPasswordForm.submit')}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export { ForgotPasswordForm }

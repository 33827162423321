import React from 'react'
import {
  GetEstateVisitSpansQuery,
  useUpdateVisitSlotIsVisitDoneMutation,
} from '@gql'
import { Button } from '@/components/ui/button'
import { CheckIcon } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'

type EstateVisitDoneButtonProps = {
  visitSlot: GetEstateVisitSpansQuery['visit_span'][number]['visit_slots'][number]
}

export const EstateVisitDoneButton = ({
  visitSlot,
}: EstateVisitDoneButtonProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [updateVisitSlotIsVisitDone] = useUpdateVisitSlotIsVisitDoneMutation()

  const handleUpdateSlot = (isVisitDone: boolean) =>
    updateVisitSlotIsVisitDone({
      variables: {
        id: visitSlot.id,
        isVisitDone,
      },
      onCompleted: () => {
        toast({
          description: t('EstateVisitDoneButton.toast.success'),
          variant: 'success',
        })
      },
      onError(error) {
        toast({
          description: error.message,
          variant: 'destructive',
        })
      },
    })

  return (
    <>
      <p className="tw-text-zinc-900 tw-font-medium">
        {visitSlot.buyer_member.name}
      </p>
      {visitSlot.isVisitDone ? (
        <Button size="sm" onClick={() => handleUpdateSlot(false)}>
          <CheckIcon className="tw-h-5 tw-w-5" />
          <span className="tw-ml-1">
            {t('EstateVisitDoneButton.button.undone')}
          </span>
        </Button>
      ) : (
        <Button
          size="sm"
          variant="outline"
          onClick={() => handleUpdateSlot(true)}
        >
          {t('EstateVisitDoneButton.button.done')}
        </Button>
      )}
    </>
  )
}

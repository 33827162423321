import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import slugify from 'slugify'
import { trpc } from 'src/trpc'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from '@/components/ui/dialog'
import { Building2Icon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'
import { useUserData } from '@nhost/react'
import useSeller from '../hooks/useSeller'
import { useNavigate } from 'react-router-dom'
import { getSellerPath } from '@almaris/shared/helpers/getSellerPath'
import { useToast } from '@/components/ui/use-toast'
import { SellerFragment } from '@gql'
import {
  SellerCreateFormValues,
  useSellerCreateSchema,
} from '../hooks/useSellerCreateSchema'
import { Loading } from '@/common/atoms/Loading'

type SellerCreateModalProps = {
  onSuccess?: (seller: SellerFragment) => void
}

export default function SellerCreateModal({
  onSuccess: _onSuccess,
}: SellerCreateModalProps) {
  const { t } = useTranslation()
  const { toast } = useToast()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const currentMember = useUserData()
  const [loading, setLoading] = useState(false)
  const [sellerId, setSellerId] = useState<string | undefined>()
  const seller = useSeller(sellerId)
  const onSuccess =
    _onSuccess ??
    ((seller: SellerFragment) => navigate(getSellerPath({ id: seller.id })))

  const { schema } = useSellerCreateSchema()

  const form = useForm<SellerCreateFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      seller: {
        name: '',
        slug: '',
      },
      member: {
        name: '',
        email: '',
        phone: '',
      },
    },
  })

  // Update slug value when name changes
  const name = form.watch('seller.name')

  useEffect(() => {
    if (name) {
      form.setValue(
        'seller.slug',
        slugify(name, { strict: true }).toLowerCase()
      )
    }
  }, [name])

  const onSubmit = async (values: SellerCreateFormValues) => {
    //First create seller
    setLoading(true)
    try {
      // Create seller
      const { insert_seller_one: seller } =
        await trpc.seller.createSeller.mutate(values)

      if (seller) {
        setSellerId(seller?.id)

        // Invite main contact
        const invitedSellerMember = seller.seller_members.find(
          (m) => m.isMainContact === true
        )

        if (invitedSellerMember) {
          await trpc.member.inviteMember.mutate({
            memberId: String(invitedSellerMember.id),
            email: String(invitedSellerMember.inviteEmail),
            inviterMember:
              currentMember?.displayName ?? currentMember?.email ?? '',
          })
        }
      }
      setLoading(false)
      toast({
        variant: 'success',
        description: t('SellerCreateModal.success'),
      })
      setIsOpen(false)
    } catch (error: any) {
      setLoading(false)

      const message =
        error.message === 'Conflict'
          ? t('SellerCreateModal.error.already-exists')
          : error.message || error.toString()

      toast({
        variant: 'destructive',
        description: message,
      })
    }
  }

  // Redirect after creation to new seller
  useEffect(() => {
    if (seller) {
      setIsOpen(false)
      onSuccess(seller)
    }
  }, [seller])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button>
          <Building2Icon className="tw-h-4 tw-w-4 tw-mr-2" />
          {t('SellerCreateModal.button')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="tw-mb-5">
          <DialogTitle>{t('SellerCreateModal.heading')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-5">
            <FormField
              control={form.control}
              name="seller.name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerCreateModal.seller.name')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('SellerCreateModal.seller.placeholder')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="seller.slug"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerCreateModal.seller.slug')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="tw-my-6" />
            <h3 className="tw-text-md tw-font-semibold">
              {t('SellerCreateModal.member.heading')}
            </h3>
            <FormField
              control={form.control}
              name="member.name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerCreateModal.member.name')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        'SellerCreateModal.member.namePlaceholder'
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="member.email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerCreateModal.member.email')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        'SellerCreateModal.member.emailPlaceholder'
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="member.phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerCreateModal.member.phone')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        'SellerCreateModal.member.phonePlaceholder'
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="tw-mt-6">
              <Button variant="green" type="submit">
                {loading ? <Loading active /> : t('SellerCreateModal.submit')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

import {
  Address,
  AddressCombobox,
  AddressComboboxModeEnum,
} from '@/api-adresse/AddressCombobox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  EstateIdentityAndAdressSchema,
  useEstateIdentityAndAdressSchema,
} from '@/estate/hooks/useEstateIdentityAndAdressSchema'
import { WebappEstateFragment } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type EstateIdentityAndAddressFormProps = {
  estate: WebappEstateFragment
  onSubmit: (values: EstateIdentityAndAdressSchema) => void
}
export const EstateIdentityAndAddressForm = ({
  estate,
  onSubmit,
}: EstateIdentityAndAddressFormProps) => {
  const { t } = useTranslation()

  const { schema } = useEstateIdentityAndAdressSchema()

  const defaultValues: EstateIdentityAndAdressSchema = {
    name: estate.name ?? '',
    address: estate.address ?? '',
    city: estate.city ?? '',
    zipCode: estate.zipCode ?? '',
  }

  const form = useForm<EstateIdentityAndAdressSchema>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onInvalid = (errors: FieldErrors<EstateIdentityAndAdressSchema>) => {
    console.log({ errors })
  }

  useEffect(() => {
    const { unsubscribe } = form.watch((value, { type }) => {
      if (type !== 'change') return
      form.handleSubmit(onSubmit, onInvalid)()
    })
    return () => unsubscribe()
  }, [form.watch])

  return (
    <Form {...form}>
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('EstateIdentityAndAddressForm.name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => {
            const handleSelect = ({ address, zipCode, city }: Address) => {
              form.setValue('address', address!)
              form.setValue('zipCode', zipCode!)
              form.setValue('city', city!)
            }
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateIdentityAndAddressForm.address')} *
                </FormLabel>
                <FormControl>
                  <AddressCombobox
                    {...field}
                    onSelectItem={handleSelect}
                    mode={AddressComboboxModeEnum.ADDRESS}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="zipCode"
          render={({ field }) => {
            const handleSelect = (value: Address) => {
              form.setValue('zipCode', value.zipCode!)
              form.setValue('city', value.city!)
            }
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateIdentityAndAddressForm.zipCode')} *
                </FormLabel>
                <FormControl>
                  <AddressCombobox
                    {...field}
                    onSelectItem={handleSelect}
                    mode={AddressComboboxModeEnum.ZIP_CODE}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => {
            const handleSelect = (value: Address) => {
              form.setValue('city', value.city!)
            }
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateIdentityAndAddressForm.city')} *
                </FormLabel>
                <FormControl>
                  <AddressCombobox
                    {...field}
                    onSelectItem={handleSelect}
                    mode={AddressComboboxModeEnum.CITY}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
      </div>
    </Form>
  )
}

import { CardFooter } from '@/components/ui/card'
import { useEstateStats } from '@/estate/hooks/useEstateStats'
import { WebappEstateFragment } from '@gql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type EstatesCardListFooterProps = {
  estate: WebappEstateFragment
}
export const EstatesCardListFooter = ({
  estate,
}: EstatesCardListFooterProps) => {
  const { t } = useTranslation()
  const {
    nbPlannedVisits,
    nbDoneVisits,
    nbInProgressApplications,
    nbReceivedApplications,
  } = useEstateStats(estate)

  return (
    <CardFooter className="tw-flex tw-justify-between tw-items-center tw-gap-4">
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.visitsPlanned', {
            count: nbPlannedVisits,
          })}
        </p>
      </div>
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.visitsDone', {
            count: nbDoneVisits,
          })}
        </p>
      </div>
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.offersPrepared', {
            count: nbInProgressApplications,
          })}
        </p>
      </div>
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.offersReceived', {
            count: nbReceivedApplications,
          })}
        </p>
      </div>
    </CardFooter>
  )
}

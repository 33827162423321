import React, { useCallback, useState } from 'react'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import {
  Application_Status_Enum,
  WebappApplicationFragment,
  WebappEstateFragment,
  useUpdateApplicationAndArchiveOthersMutation,
  useUpdateEstateMutation,
} from '@gql'
import AdminHeader from '@/application/components/sheet/AdminHeader'
import AdminContent from '@/application/components/sheet/AdminContent'
import ApplicationFooter from '@/application/components/sheet/Footer'
import SellerHeader from '@/application/components/sheet/SellerHeader'
import SellerContent from '@/application/components/sheet/SellerContent'
import { toast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { trpc } from 'src/trpc'
import { Button } from '@/components/ui/button'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'

type ApplicationSheetProps = {
  application: WebappApplicationFragment
  estate: WebappEstateFragment
  onNext: (() => void) | null
  onPrevious: (() => void) | null
  isAdmin: boolean
} & React.ComponentProps<typeof Sheet>

const ApplicationSheet = ({
  application,
  estate,
  onNext,
  onPrevious,
  isAdmin,
  ...sheetProps
}: ApplicationSheetProps) => {
  const { t } = useTranslation()
  const [openConfirm, setOpenConfirm] = useState(false)

  const [updateEstate] = useUpdateEstateMutation()

  const [updateApplicationAndArchiveOthers] =
    useUpdateApplicationAndArchiveOthersMutation({
      onCompleted: () => {
        toast({
          title: t('ApplicationSheet.toast.success'),
          variant: 'success',
        })
      },
      onError: () => {
        toast({
          title: t('ApplicationSheet.toast.error'),
          variant: 'destructive',
        })
      },
    })

  const acceptOffer = useCallback(async () => {
    if (!application || application.archived) return

    const newSaleData = estate.saleData.secondRound
      ? {
          saleOpen: false,
          ...estate.saleData,
          secondRound: {
            ...estate.saleData.secondRound,
          },
        }
      : {
          saleOpen: false,
          firstRound: {
            ...estate.saleData.firstRound,
          },
        }

    await updateEstate({
      variables: {
        id: estate.id,
        values: {
          saleData: newSaleData,
        },
      },
      onError: (error) => {
        toast({
          title: t('ApplicationSheet.toast.error'),
          description: error.message,
          variant: 'destructive',
        })
      },
    })

    const { data } = await updateApplicationAndArchiveOthers({
      variables: {
        id: application.id,
        estateId: estate.id,
        status: Application_Status_Enum.Accepted,
      },
    })

    if (data?.update_application_by_pk?.id) {
      try {
        await trpc.application.acceptApplicationMail.mutate({
          applicationId: application.id,
        })
        toast({
          title: t('ApplicationSheet.sendMail.toast.success'),
          variant: 'success',
        })
      } catch (error) {
        toast({
          description: error instanceof Error ? error.message : '',
          variant: 'destructive',
        })
      }
    }
  }, [application])

  const canAcceptOffer =
    isAdmin &&
    application.status &&
    [
      Application_Status_Enum.Submitted,
      Application_Status_Enum.Accepted,
    ].includes(application.status)

  return (
    <Sheet {...sheetProps}>
      <SheetContent className="tw-flex tw-flex-col tw-gap-6">
        <SheetTitle />
        <SheetHeader>
          {isAdmin && (
            <AdminHeader
              onNext={onNext}
              onPrevious={onPrevious}
              propertyAddress={estate.address}
            />
          )}
          {!isAdmin && (
            <SellerHeader candidatName={application.buyer_member.name ?? ''} />
          )}
        </SheetHeader>
        <Separator />
        <ScrollArea className="tw-h-[calc(100vh-100px)] tw-w-full tw-px-9">
          {isAdmin && <AdminContent application={application} />}
          {!isAdmin && <SellerContent application={application} />}
        </ScrollArea>
        <Separator />
        <ApplicationFooter application={application} />
        {canAcceptOffer && (
          <div>
            <Button
              type="button"
              disabled={
                application.status === Application_Status_Enum.Accepted ||
                application.archived
              }
              onClick={() => setOpenConfirm(true)}
            >
              {t('ApplicationSheet.sendMail.button')}
            </Button>
            <ConfirmationDialog
              open={openConfirm}
              onOpenChange={setOpenConfirm}
              onConfirm={acceptOffer}
              description={t('ApplicationSheet.sendMail.description')}
            />
          </div>
        )}
      </SheetContent>
    </Sheet>
  )
}

export default ApplicationSheet

import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { useNameSchema } from '@/common/hooks/schemas/useNameSchema'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useUserInfoSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })
  const { schema: nameSchema } = useNameSchema()
  const { schema: emailSchema } = useEmailSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: nameSchema,
        email: emailSchema,
      }),
    [t, nameSchema, emailSchema]
  )

  return { schema }
}

export type UserInfoFormValues = yup.InferType<
  ReturnType<typeof useUserInfoSchema>['schema']
>

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Title } from '@/common/atoms/Title'
import { EstatesTable } from '../components/table/EstatesTable'
import { EstateAddFormModal } from '../components/Estate/EstateAddFormModal'
import useEstates from '../hooks/useEstates'
import { EstateArchiveDialog } from '../components/EstateArchiveDialog/EstateArchiveDialog'
import { EstateArchiveDialogProvider } from '../context/EstateArchiveDialogContext'

export const EstatesAgencyPage = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const [includeArchived, setIncludeArchived] = useState(false)

  const { estates: all_estates, loading, error } = useEstates()

  const estates = includeArchived
    ? all_estates
    : all_estates?.filter((estate) => !estate.archived)

  return (
    <>
      <Title>{t('EstatesPage.heading')}</Title>
      {loading && <Loading center active />}
      {error && <TextError error={error} />}
      <div className="tw-p-8">
        <div className="tw-flex tw-justify-between tw-items-baseline">
          <h1 className="tw-text-[40px] tw-font-semibold">
            {t('EstatesPage.heading')}
          </h1>
          <EstateAddFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <EstateArchiveDialogProvider>
          {estates && (
            <EstatesTable
              estates={estates}
              setIncludeArchived={setIncludeArchived}
            />
          )}
          <EstateArchiveDialog />
        </EstateArchiveDialogProvider>
      </div>
    </>
  )
}

import Homepage from '@/common/pages/Homepage'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthLayout } from 'src/features/layout/AuthLayout'
import { ForgotPasswordForm } from 'src/features/user/components/ForgotPasswordForm'
import { ResetPasswordPage } from 'src/features/user/pages/ResetPasswordPage'

export default function PublicRoute() {
  const pathname = window.location.pathname
  return (
    <Routes>
      <Route index element={<Homepage />} />
      <Route path="/" element={<AuthLayout />}>
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route path="forgot-password" element={<ForgotPasswordForm />} />
      </Route>
      <Route
        path="*"
        element={
          <Navigate to={`/?redirectTo=${encodeURIComponent(pathname)}`} />
        }
      />
    </Routes>
  )
}
